<template>
  <v-row no-gutters>
    <Parent />
    <v-col class="mt-3" cols="12">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="auto">{{ $t("notification_list") }}</v-col>

            <v-spacer></v-spacer>

            <v-col cols="3">
              <TableSearch
                :search.sync="search"
                @update:search="getDataFromApi"
              ></TableSearch>
            </v-col>

            <v-col cols="auto">
              <v-btn
                color="primary"
                text
                class="text-capitalize font-weight-regular"
                to="/notification/create"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("page_notifications_draft_create_new_text") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0">
          <Table
            v-if="pagination && notifications"
            :headers="headers"
            :items="notifications"
            :loading="loading"
            :total-records="pagination.records_total"
            :number-of-pages="pagination.total_pages"
            @click:row="goToEdit"
            @update:options="updateTable"
          >
            <template v-slot:[`item.id`]="{ item }">
              <span class="primary--text">{{ item.id }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <span
                class="text-capitalize"
                :class="'text-status-' + item.status"
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <span
                v-if="item.type"
                class="text-capitalize"
                :class="
                  item.type.id === 'important' ? 'text-type-important' : ''
                "
                >{{ item.type.name }}</span
              >
            </template>

            <template v-slot:[`item.category`]="{ item }">
              <span class="text-capitalize">{{ item.category.name }}</span>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <span class="text-capitalize">{{ item.type.name }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <span class="text-capitalize">{{ item.created_at }}</span>
            </template>

            <template v-slot:[`item.tags`]="{ item }">
              {{ Array.prototype.map.call(item.tags, s => s.name).join(", ") }}
            </template>

            <template v-slot:[`item.view`]="{ item }">
              {{ item.view.toLocaleString() }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="secondary"
                text
                :to="`/notification/${item.id}/edit`"
              >
                <v-icon size="12">$edit</v-icon>
              </v-btn>
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Parent from "@/views/admin/Notification/index";
import Table from "@/components/admin/partials/Table/Table";
import TableSearch from "@/components/admin/partials/Search/TableSearch";

export default {
  name: "index",
  components: { Table, Parent, TableSearch },
  created() {
    this.getDataFromApi();
  },

  computed: {
    notifications() {
      return this.$store.getters.allNotifications;
    },
    pagination() {
      return this.$store.getters.notificationPagination;
    }
  },

  data() {
    return {
      headers: [
        { text: this.$t("id"), align: "center", value: "id" },
        {
          text: this.$t("page_notifications_draft_table_header_title"),
          align: "center",
          value: "title"
        },
        {
          text: this.$t("page_notifications_draft_table_header_type"),
          align: "center",
          value: "type",
          sortable: false
        },
        {
          text: this.$t("page_notifications_draft_table_header_category"),
          align: "center",
          value: "category"
        },
        {
          text: this.$t("page_notifications_draft_table_header_tags"),
          align: "center",
          value: "tags",
          sortable: false
        },
        {
          text: this.$t("page_notifications_draft_table_header_view"),
          align: "center",
          value: "user_notification_read_count",
          sortable: false
        },
        {
          text: this.$t("page_notifications_draft_table_header_created"),
          align: "center",
          value: "created_at"
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      loading: false,
      search: "",
      initialLoad: true
    };
  },

  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    async getDataFromApi(e = undefined) {
      this.loading = true;
      let data = {};
      data.is_draft = "1";

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc[0] ? "desc" : "asc";
        data.sort = e.sortBy[0];
      } else {
        data.sort = "id";
        data.order = "desc";
      }

      if (e?.page !== undefined && e?.paginate !== undefined) {
        data.page = e.page;
        data.paginate = e.paginate;
      }

      if (this.search) {
        data.search = this.search;
      }

      if (this.search) {
        data.search = this.search;
      }

      await this.$store
        .dispatch("GET_ALL_NOTIFICATIONS", data)
        .then(() => {
          if (this.initialLoad) this.initialLoad = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToEdit(item) {
      this.$router.push({
        name: "NotificationEdit",
        params: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
