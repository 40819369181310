var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('Parent'), _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.$t("notification_list")))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('TableSearch', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "update:search": [function ($event) {
        _vm.search = $event;
      }, _vm.getDataFromApi]
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "color": "primary",
      "text": "",
      "to": "/notification/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("page_notifications_draft_create_new_text")) + " ")], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_vm.pagination && _vm.notifications ? _c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.notifications,
      "loading": _vm.loading,
      "total-records": _vm.pagination.records_total,
      "number-of-pages": _vm.pagination.total_pages
    },
    on: {
      "click:row": _vm.goToEdit,
      "update:options": _vm.updateTable
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-capitalize",
          "class": 'text-status-' + item.status
        }, [_vm._v(_vm._s(item.status))])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.type.name))])];
      }
    }, {
      key: "item.category",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.category.name))])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.created_at))])];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(Array.prototype.map.call(item.tags, function (s) {
          return s.name;
        }).join(", ")) + " ")];
      }
    }, {
      key: "item.view",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.view.toLocaleString()) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary",
            "text": "",
            "to": "/notification/".concat(item.id, "/edit")
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "12"
          }
        }, [_vm._v("$edit")])], 1)];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }